.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 325px;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.popup-content {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 5px;
  padding: 10px;
}

.popup-header
{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ol-popup-closer{
  background-color: unset;
  border: unset;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.ol-popup-closer:hover {
color: var(--blue-hover);
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table th {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .popup-content {
    width: 90%;
    padding: 5px;
  }
}
