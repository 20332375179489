@import url("../node_modules/fontawesome-free/css/all.min.css");
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url("../node_modules/open-props/open-props.min.css");
@import url("../node_modules/ol/ol.css");
@import "https://unpkg.com/open-props";
@import url("./styles/buttons.css");
@import url("./styles/forms.scss");
@import url("./styles/esk-toolbar.css");
@import url("./styles/display.helpers.scss");
@import url("./styles/overrides.scss");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap");
@import url("./styles/esk-tooltip.css");
@import url("./app/admin/styles/admin.css");
@import url("./styles/popup.css");
html, body {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  display: grid;
  background-color: white;
}

::after, ::before, * {
  box-sizing: border-box;
}

*:not(mat-icon, i) {
  font-family: "Outfit", sans-serif !important;
}

.map {
  height: 100%;
  width: 100%;
}

:root {
  --button-color: #292928;
  --button-bg: #292928;
  --button-size: 1em;
  --button-border-radius: 0.25em;
  --mdc-snackbar-supporting-text-font: "Outfit";
  --button-disabled-color: #cccccc;
  --button-disabled-background-color: #666666;
  --status-color: white;
  --measure-tool-primary: rgb(255,0,255,1);
}

.link {
  stroke: #C0C0C0;
  fill: transparent;
}

.mdc-snackbar.success-snack-bar .mdc-snackbar__surface {
  background-color: var(--green);
  border-color: var(--green-border);
  color: #FFFFFF;
}

.mdc-snackbar .mat-mdc-snack-bar-label {
  --mdc-snackbar-supporting-text-size: 1.1rem;
  text-align: center;
}

.mdc-snackbar.error-snack-bar .mdc-snackbar__surface {
  background-color: var(--red);
  border-color: var(--red-border);
  color: #FFFFFF;
}

.mdc-snackbar.notification-snack-bar .mdc-snackbar__surface {
  background-color: var(--blue);
  border-color: var(--blue-border);
  color: #FFFFFF;
}

.example-viewport {
  display: flex;
}

.icon-wrapper {
  --size: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--size, 40px);
  height: var(--size, 40px);
  border-radius: 50%;
  border: 2px solid;
}

.icon-wrapper.valid {
  background-color: var(--green);
  border: 2px solid var(--green-border);
}

.icon-wrapper.invalid {
  background-color: var(--red);
  border: 2px solid var(--red-border);
}

.icon {
  fill: white;
}

.status-label {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 0.75em;
  text-transform: uppercase;
  color: var(--status-color);
  padding: 0.25em 0.5em;
}

.status-1 {
  --status-color: #6699cc;
}

.status-2 {
  --status-color: #ffcc00;
}

.status-3 {
  --status-color: #33cc33;
}

.environment-overlay {
  position: absolute;
  font-size: 1.25rem;
  bottom: 10%;
  left: 10px;
  border: 2px solid var(--red);
  background-color: rgba(255, 255, 255, 0.25);
  -webkit-text-stroke-width: 0.25px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.541);
  color: var(--red);
  padding: 1rem;
}