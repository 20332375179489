
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container header {
  margin-bottom: 20px;
}

.form-container h1 {
  font-size: 2em;
}

.form-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-container .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}


.form-container .button {
  padding: 10px;
  border: none;
  background-color: #555;
  color: #fff;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.form-container .button:hover {
  background-color: #333;
}

.form-container .button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


.form-container .error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  margin-top: 1rem;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ffcdd2;
}


.form-container .version_information
{
  margin-block-start: 1rem;
  color: var(--gray-4);
  text-align: center;
  display: flex;
  justify-content: center;
}
