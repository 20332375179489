.mdc-dialog__title+.mdc-dialog__content, .mdc-dialog__header+.mdc-dialog__content {
    padding-top: 1rem !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
    border-color: #c6c8c4;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label
  {
    color: #767371;
  }
  .mdc-data-table__cell{
    height: 56px;
    //max-width: 50px;
    text-align: center;
  }
  .mat-form-field-no-padding .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-dialog__title+.mdc-dialog__content, .mdc-dialog__header+.mdc-dialog__content{
    padding-inline-start: 1rem;
  }
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar{
    background-color: rgba(128, 128, 128, 0.61);
  }

  .mdc-data-table__header-cell {
    text-align: center !important;
  }
mat-dialog-actions footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


@media (max-width: 600px) {
  .myDialogClass {
    max-width: 95dvw !important;
    max-height: 95dvh !important;
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .myDialogClass {
    max-width: 80dvw !important;
    max-height: 80dvh !important;
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 1025px) {
  .myDialogClass{
    max-width: 70dvw !important;
    max-height: 80dvh !important;
    height: fit-content !important;
    width: fit-content !important;
  }

  .myDialogClass.map-container{
    height: 100% !important;
    width: 100% !important;
  }
}


.mat-drawer .mat-drawer-inner-container
{
  height: 100dvh;
  overflow: unset;
}


.stuff .mat-mdc-text-field-wrapper {
  flex: unset
}


.mat-mdc-dialog-actions {
  gap: 0.5rem;
}


.mat-drawer {
  overflow-y:unset !important;
}

.mat-expansion-panel-header span {
  overflow: unset;
}
