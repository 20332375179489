.route-container {
  display: flex;
  max-height: calc(100dvh - 65px);
  min-height: calc(100dvh - 65px);
  padding: 2rem;
  background-color: #ECEFF1;
  justify-content: center;
}

mat-card.list-view-card {
  max-width: calc(100% - 4rem);
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-auto-rows: 75px 1fr;
}


mat-card.list-view-card .list-view_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
}


.filter-container mat-form-field {
  width: 200px;
}


.table-container {
  padding-inline: 2rem;
  display: grid;
  grid-auto-rows: 1fr 55px;
  overflow-y: hidden;
  padding-block-end: 1rem;
}

.table-container mat-paginator {
  align-self: end;
}

/* Create User Card */



.table-container .scrollable-container {
  max-height: 900px;
  min-height: 350px;
  overflow-y: auto;
  margin-block: 2rem;
  position: relative;
}

.mat-header-row--sticky {
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 100;
}

.scrollable-container thead {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.scrollable-container mat-row:nth-child(even) {
  background-color: #f5f5f5; /* Light gray */
}

.scrollable-container mat-row:nth-child(odd) {
  background-color: #ffffff; /* White */
}

.scrollable-container mat-row :last-child:not(button) {
  margin-left: auto;

}

.scrollable-container mat-header-row {
  background-color: var(--adm-table-header-bg, #455a64);
  color: #ffffff;
  border: none;
}

.scrollable-container mat-header-row :last-child{
  margin-left: auto;
}


.scrollable-container mat-header-row th {
  color: #ffffff; /* White */
  border-bottom: none;
}

.scrollable-container table {
  width: 100%;
}


.scrollable-container td, .scrollable-container th {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 768px) {

  mat-card.list-view-card {
    max-width: 100vw;
    grid-auto-rows: 150px 1fr;
  }

  .table-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  .table-container .scrollable-container {
  margin-block:0;
  max-height: 400px;

}

.table-container {
  padding-inline: 0;
}

mat-card.list-view-card .list-view_header {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 1rem;

}

.filter-container{
  width: 100%
}
.filter-container mat-form-field
{
  width: 100%;
}

.user-management-header_actions
{
width: 100%;
}
.user-management-header_actions button
{
width: 100%;
}

.route-container {
  min-height: calc(100dvh - 120px);
  padding: 1rem;

}
}
