
.grid--column {
    display:grid;
    grid-auto-flow: column;
  }

  .grid--row {
    display:grid;
    grid-auto-flow: row;
  }

  .gap--xs {
    gap: 0.25rem;
  }

  .gap--sm {
    gap: 0.5rem;
  }

  .gap--md {
    gap: 1rem;
  }

  .gap--lg {
    gap: 1.5rem;
  }

  .gap--xl {
    gap: 2rem;
  }



  /* Flex container */
  .flex {
    display: flex;
  }

  /* Flex direction */
  .flex-row {
    flex-direction: row;
  }
  .flex-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-column-reverse {
    flex-direction: column-reverse;
  }

  /* Flex wrap */
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }

  /* Flex justify-content */
  .justify-start {
    justify-content: flex-start;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }

  /* Flex align-items */
  .align-start {
    align-items: flex-start;
  }
  .align-end {
    align-items: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .align-baseline {
    align-items: baseline;
  }
  .align-stretch {
    align-items: stretch;
  }

  /* Flex align-content */
  .align-content-start {
    align-content: flex-start;
  }
  .align-content-end {
    align-content: flex-end;
  }
  .align-content-center {
    align-content: center;
  }
  .align-content-between {
    align-content: space-between;
  }
  .align-content-around {
    align-content: space-around;
  }
  .align-content-stretch {
    align-content: stretch;
  }

  /* Flex grow */
  .flex-grow-0 {
    flex-grow: 0;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }

  /* Flex shrink */
  .flex-shrink-0 {
    flex-shrink: 0;
  }
  .flex-shrink-1 {
    flex-shrink: 1;
  }

  /* Flex basis */
  .flex-basis-auto {
    flex-basis: auto;
  }
  .flex-basis-0 {
    flex-basis: 0;
  }
  .flex-basis-full {
    flex-basis: 100%;
  }
