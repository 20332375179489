.esk-toolbar-tooltip {
  color: var(--measure-tool-primary) !important;
  font-size: 1.25rem;
  -webkit-text-stroke-width: 0.25px;
  -webkit-text-stroke-color: black;
}


.esk-toolbar{
  position: absolute;
  max-height: calc(100% - 2rem);
  display: grid;
  grid-auto-flow: row;
  gap: var(--size-1);
  padding-block: var(--size-2);
  padding-inline: var(--size-2);
  left: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-2);
  top:  75px;

}


.esk-tool{
  position:unset;
    border-radius: 2px;
    color: black;
    aspect-ratio: 1;
    background-color: transparent;
    transition: background-color .1s ease-in-out;
    border: none;
    cursor: pointer;
    width: 100%;

}

.esk-tool--disabled,
.esk-tool:disabled{
  cursor: not-allowed;
  pointer-events: none;

  color: #c0c0c0;
}

.esk-tool:active {

  color: var(--blue);
}

.esk-tool button {
  width: 100%;
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.esk-tool:hover,
.esk-tool *:hover {

  color:  var(--blue);

}

.esk-tool button:hover{
  outline: none;
}


.esk-tool--active {
  background-color: #d7e3fa;
  color:  var(--blue);
}


.measure-type > *{
  display: block;
}


.measure-color > * {
  display: block;
}


.measure-type--select {
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
}

.measure-color--input{
width: 100px;
}

.measure-tool-options{
  width: 80%;
  padding: 1em;
  display:grid;
}
